import React from 'react';
import { Row, Col } from 'reactstrap';

export const ScoresHeader: React.FC = () => (
    <Row className="header">
        <Col xs={12} sm={5}>Player</Col>
        <Col xs={5} sm={3}>Off</Col>
        <Col xs={5} sm={3}>Def</Col>
        <Col xs={1} sm={1}>G</Col>
    </Row>
);
