import React from 'react';
import { Button, ButtonGroup, Col, Badge } from 'reactstrap';

import { Post } from './models';

export interface ICounterProps {
    count: number;
    post: Post,
    setCountHandler: (count: number, post: Post) => void;
}

export const Counter: React.FC<ICounterProps> = ({ count, post, setCountHandler }) => {
    const increment = () => { setCountHandler(count + 1, post); }
    const decrement = () => { setCountHandler(count - 1, post); }

    return (
        <>
            <Col xs={5} sm={3} className="goalsCol">
                <Badge color="info">
                    {count}
                </Badge>
                {' '}
                <ButtonGroup>
                    <Button outline color="primary" onClick={increment}>+</Button>
                    <Button outline color="secondary" onClick={decrement}>-</Button>
                </ButtonGroup>
            </Col>
        </>
    )
};
