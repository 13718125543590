export enum Team {
    Black = 'B',
    Yellow = 'Y',
};

export enum Post {
    Offense = 'O',
    Defense = 'D',
}

export interface IPlayer {
    id: number;
    name: string;
    goalsD: number;
    goalsO: number;
    team: Team;
    active: boolean;
}
