export const data = {
    players: [
        {
            id: 1,
            name: 'Beda',
        },
        {
            id: 2,
            name: 'Guris',
        },
        {
            id: 3,
            name: 'Jarda',
        },
        {
            id: 4,
            name: 'LukasP',
        },
        {
            id: 5,
            name: 'LukasS',
        },
        {
            id: 6,
            name: 'Patrik',
        },
    ],
};
