import React from 'react';
import { Row, Col } from 'reactstrap';

import { IPlayer, Post, Team } from './models';
import { Counter } from './Counter';
import { TeamSwitch } from './TeamSwitch';

export interface IPlayerProps {
    player: IPlayer;
    goalsHandler: (playerId: number, goals: number, post: Post) => void;
    teamSwitchHandler: (playerId: number, team: Team) => void;
}

export const Player: React.FC<IPlayerProps> = (props) => {
    const { player, goalsHandler, teamSwitchHandler } = props;

    const processGoals = (count: number, post: Post) => {
        goalsHandler(player.id, count, post);
    }

    const switchTeam = (team: Team) => {
        teamSwitchHandler(player.id, team);
    }

    return (
        <Row>
            <Col xs={6} sm={3} >
                {player.name}
            </Col>
            <Col xs={5} sm={2} className="teamCol">
                <TeamSwitch activeTeam={player.team} changeHandler={switchTeam} />
            </Col>
            {/* <Col sm={1}>
                <Badge color={player.team === Team.Black ? 'secondary' : 'warning'}>X</Badge>
            </Col> */}
            <Counter count={player.goalsO} setCountHandler={processGoals} post={Post.Offense} />
            <Counter count={player.goalsD} setCountHandler={processGoals} post={Post.Defense} />
            <Col xs={1} sm={1} className="bold">
                {player.goalsO + player.goalsD}
            </Col>
        </Row>
    );
}
