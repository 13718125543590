import React from 'react';
import { Container, Button } from 'reactstrap';

import { IPlayer } from './models';

export interface IPlayersPoolProps {
    players: IPlayer[],
    activeHandler: (id: number) => void;
}

export const PlayersPool: React.FC<IPlayersPoolProps> = ({ players, activeHandler }) => {
    return (
        <Container fluid id="playersPool">
            {players.map((p) => (
                <Button className="playerButton" outline={!p.active} key={p.id} onClick={() => activeHandler(p.id)}>{p.name}</Button>
            ))}
        </Container>
    )
};
