import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import { data } from './data.json';
import { Team, Post, IPlayer } from './models';
import { Scores } from './Scores';
import { PlayersPool } from './PlayersPool';

const LS_KEY = 'players';

const initPlayers = () => {
    const lsData = localStorage.getItem(LS_KEY);
    if (lsData != null) {
        return JSON.parse(lsData);
    }

    return data.players.map((player) => {
        return {
            id: player.id,
            name: player.name,
            team: Team.Black,
            goalsD: 0,
            goalsO: 0,
            active: false,
        };
    });
}

export const App: React.FC = () => {
    const [players, setPlayers] = useState<IPlayer[]>(initPlayers());
    const activePlayers = players.filter((p) => p.active);

    useEffect(() => {
        localStorage.setItem(LS_KEY, JSON.stringify(players));
    }, [players]);

    const toggleActive = (id: number) => {
        if (activePlayers.length === 4) {
            console.log('max 4 players');
            return;
        }

        const newActive = players.map((p) => (
            p.id === id
                ? { ...p, active: !p.active }
                : p
        ));

        setPlayers(newActive);
    }

    const updateGoals = (id: number, goals: number, post: Post) => {
        if (goals < 0 || goals > 10) return;

        const newGoals = players.map((p) => {
            const goalsD = post === Post.Defense ? goals : p.goalsD;
            const goalsO = post === Post.Offense ? goals : p.goalsO;

            return (
                p.id === id
                    ? { ...p, goalsD, goalsO }
                    : p
            )
        });

        setPlayers(newGoals);
    }

    const switchTeams = (playerId: number, team: Team) => {
        const newTeams = players.map((p) => {
            return (
                p.id === playerId
                    ? { ...p, team }
                    : p
            )
        });

        setPlayers(newTeams);
    };

    const reset = () => {
        if (!window.confirm('Really?')) {
            return;
        };

        localStorage.removeItem(LS_KEY);
        setPlayers(initPlayers());
    }

    return (
        <div className="App">
            <PlayersPool players={players} activeHandler={toggleActive} />
            <Scores players={activePlayers} goalsHandler={updateGoals} teamSwitchHandler={switchTeams} />
            <div className="reset">
                <Button color="danger" onClick={reset}>Reset</Button>
            </div>
        </div>
    );
}
