import React from 'react';
import { Row, Col } from 'reactstrap';
import { IPlayer } from './models';

export interface IScoresFooterProps {
    players: IPlayer[];
}

export const ScoresFooter: React.FC<IScoresFooterProps> = ({ players }) => {
    const sumDefense = players.reduce((p, c) => { return p + c.goalsD; }, 0);
    const sumForward = players.reduce((p, c) => { return p + c.goalsO; }, 0);

    return (
        <Row className="footer">
            {/* <Col sm={1}>Team</Col> */}
            <Col xs={{ size: 5, offset: 1 }} sm={{ size: 3, offset: 6 }} >{sumForward}</Col>
            <Col xs={5} sm={3}>{sumDefense}</Col>
        </Row>);
}
