import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';

import { Team } from './models';

interface ITeamSwitchProps {
    activeTeam: Team;
    changeHandler: (team: Team) => void;
}

export const TeamSwitch: React.FC<ITeamSwitchProps> = (props) => {
    const { activeTeam, changeHandler } = props;

    const setTeam = (team: Team) => {
        changeHandler!(team);
    }

    return (
        <ButtonGroup>
            <Button outline color="dark" onClick={() => setTeam(Team.Black)} active={activeTeam === Team.Black}>B</Button>
            <Button outline color="warning" onClick={() => setTeam(Team.Yellow)} active={activeTeam === Team.Yellow}>Y</Button>
        </ButtonGroup>
    )
}
