import React from 'react';
import { Container } from 'reactstrap';

import { IPlayer, Post, Team } from './models';
import { Player } from './Player';
import { ScoresHeader } from './ScoresHeader';
import { ScoresFooter } from './ScoresFooter';

export interface IScores {
    players: IPlayer[];
    goalsHandler: (playerId: number, goals: number, post: Post) => void;
    teamSwitchHandler: (playerId: number, team: Team) => void;
}

export const Scores: React.FC<IScores> = (props) => {
    const { players, goalsHandler, teamSwitchHandler } = props;

    if (players.length === 0) {
        return null;
    }

    return (
        <Container fluid>
            <ScoresHeader />
            {players.map((p) => (
                <Player player={p} goalsHandler={goalsHandler} key={p.id} teamSwitchHandler={teamSwitchHandler} />
            ))}
            <ScoresFooter players={players} />
        </Container>)
}
